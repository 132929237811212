import React, { useEffect, useState } from 'react';
import { Box, Divider, Typography, Paper, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import Map from './Map';
import banner from './assets/Banner PEGASUS.png';
import signal from './assets/signal.png';
import screen from './assets/screen.png';
import './App.css';
import ReactCountryFlag from "react-country-flag";
import useGetIP from './hooks/useGetIP';
import useGetDeviceInfo from './hooks/useGetDeviceInfo';
import { BeatLoader } from 'react-spinners';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DoneAllIcon from '@mui/icons-material/DoneAll';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#000',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'left',
  color: '#fff',
  borderRadius: '20px',
}));

const NoBgItem = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: 'left',
  color: '#fff',
  borderRadius: '20px',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
}));

const FullPageContainer = styled(Box)({
  minHeight: '100vh',
  backgroundColor: '#000',
  color: '#fff', 
  padding: '2rem', 
  boxSizing: 'border-box', 
  overflowX: 'hidden', 
});

export default function FullWidthGrid() {
  const { ip, data, loading, version, error } = useGetIP();
  const { detectDevice, detectOS, detectBrowser, screenSize } = useGetDeviceInfo();

  const ipAddress = data?.query || 'N/A';
  const ipVersion = ip?.version || 'N/A';
  const asName = data?.as || 'N/A';
  const isp = data?.isp || 'N/A';
  const org = data?.org || 'N/A';
  const reverse = data?.reverse || 'N/A';
  const proxy = data?.proxy === 'false' ? 'NO' : 'YES';
  const lat = data?.lat || 'N/A';
  const lon = data?.lon || 'N/A';
  const district = data?.district || 'N/A';
  const city = data?.city || 'N/A';
  const country = data?.country || 'N/A';
  const countryCode = data?.countryCode || 'N/A';
  const continent = data?.continent || 'N/A';
  const currentTime = data?.currentTime || 'N/A';
  const regionName = data?.regionName || 'N/A';

  const currentDate = currentTime !== 'N/A' ? new Date(currentTime) : new Date();
  const formattedTime = currentDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });
  const formattedDate = currentDate.toLocaleDateString(undefined, { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' });

  const [copied, setCopied] = useState(false);
  const isQueryLong = data?.query && data.query.length > 50;

  useEffect(() => {
    let timer;
    if (copied) {
      timer = setTimeout(() => setCopied(false), 1000); // Reset copied state after 3 seconds
    }
    return () => clearTimeout(timer); // Cleanup the timer on unmount
  }, [copied]);

  const datas = [
    {
      title: 'Connection',
      details: [
        { label: 'IP Address', value: ipAddress },
        { label: 'AS Number and Organization', value: asName, bold: true },
        { label: 'Organization Name', value: org, bold: true },
        { label: 'ISP Name', value: isp },
        { label: 'Reverse DNS of the IP', value: reverse },
        { label: 'Is it Proxy, VPN or Tor exit address ?', value: proxy },
        { label: 'Latitude & Longitude', value: `${lat}, ${lon}` },
        { label: 'District', value: district },
        { label: 'City', value: city },
        { label: 'Region Name', value: regionName },
        { label: 'Country', value: country, isFlag: true },
        { label: 'Country Code', value: countryCode },
        { label: 'Continent', value: continent },
      ],
      imgSrc: signal
    },
    {
      title: 'Devices',
      details: [
        { label: 'Devices', value: detectDevice() },
        { label: 'OS', value: detectOS() },
        { label: 'Screen Resolution', value: `Width: ${screenSize.width}px \nHeight: ${screenSize.height}px` },
        { label: 'Browser Info', value: detectBrowser() },
      ],
      imgSrc: screen
    }
  ];

  const gradientStyle = {
    background: 'linear-gradient(90deg, #FFFF00, #FFD700, #FFFACD)', // Yellow to brighter shades of yellow
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    backgroundClip: 'text',
    color: 'transparent'
  };

  return (
    <Box sx={{ flexGrow: 1, backgroundColor: '#161617', color: '#fff', overflowX: 'hidden' }}>
      <Box sx={{
        width: '100%',
        backgroundColor: 'rgba(22, 22, 23, 0.7)', 
        backdropFilter: 'blur(10px)', 
        padding: 2,
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '30px',
        borderBottom: '1px solid #484a4b', // white border at the bottom
      }}>
        <Grid container alignItems="center" sx={{ flexWrap: 'nowrap', marginTop: "-10px" }}>
          <Grid item>
            <img 
              src="https://imagedelivery.net/96HwhxH7gfncMSTSSEw4wg/d490d13b-488c-41ef-94bb-4e23257da000/public1024" 
              width="40px" 
              style={{ marginLeft: "2%" }}
            />
          </Grid>
          <Grid item>
            <Box sx={{ 
              width: '1px', 
              height: '24px', 
              backgroundColor: '#484a4b', 
              marginLeft: '5px',
              marginRight: '10px'
            }} />
          </Grid>
          <Grid item>
            <p ><b>MY IP</b> INFORMATION</p>
          </Grid>
        </Grid>
      </Box>
      <Grid container spacing={2} p={5} >
        <Grid container spacing={2}>
          {/* Column 1 */}
          <Grid item xs={12} md={8}>
            <Item sx={{ backgroundColor: '#000', textAlign: 'center', borderRadius: '8px', height: "94%", maxHeight: "94%" }}>
              <Grid mt={-3} sx={{ textAlign: 'left' }}>
                <div style={{ border: '1px solid #000', padding: '10px', borderRadius: '5px', maxWidth: '400px', wordWrap: 'break-word', overflow: 'hidden' }}>
                  <h1 style={{fontSize:'24px'}}>
                    <span style={gradientStyle}>
                      {loading ? (
                        <BeatLoader
                          color='#FFA500'
                          loading={loading}
                          size={10}
                          aria-label="Loading Spinner"
                          data-testid="loader"
                        />
                      ) : (
                        data?.query || 'N/A'
                      )}
                    </span>{' '}
                    {loading ? (
                      <BeatLoader
                        color='#FFA500'
                        loading={loading}
                        size={10}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                      />
                    ) : (
                      <> 
                        - {ipVersion || 'N/A'}
                        <CopyToClipboard text={ipAddress} onCopy={() => setCopied(true)}>
                          <span style={{ marginLeft: 10 }}>
                            {copied ? <DoneAllIcon /> : <ContentCopyIcon />}
                          </span>
                        </CopyToClipboard>
                      </>
                    )}
                  </h1>
                <p style={{ color: '#fff', marginTop: "-20px" }}>The IP address you are connecting from</p>
                </div>
              </Grid>
              {
                lat !== 'N/A' && lon !== 'N/A' ? <Map locations={{ lat: lat, lon: lon }} /> : <h2>Loading Map...</h2>
              }
            </Item>
          </Grid>

          {/* Column 2 */}
          <Grid item xs={12} md={4}>
            <Grid container direction="column" spacing={2} sx={{ height: '100%' }}>
              {/* Box Row */}
              <Grid item>
                <Box sx={{
                  padding: 2,
                  boxShadow: 3,
                  borderRadius: 2,
                  overflow: 'hidden',
                  background: 'linear-gradient(to right, #414040, #2c2c2c)',
                  color: "#FFF",
                  textAlign: 'left',
                  fontFamily: 'Arial, sans-serif',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}>
                  <Grid>
                    <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>{regionName}, {city}</Typography>
                    <Typography sx={{ fontSize: '14px' }}>{formattedDate}</Typography>
                    <Typography sx={{ fontSize: '14px' }}>{continent}, {country}</Typography>
                  </Grid>
                  <Grid>
                    <Typography sx={{ fontSize: '40px', fontWeight: '500', margin: '0 0 4px 0' }}>{formattedTime}</Typography>
                  </Grid>
                </Box>

                {/* Image Row */}
                <Grid item mt={2}>
                  <NoBgItem sx={{ display: 'flex', flexDirection: 'column', borderRadius: "10px" }}>
                    <a href="https://wa.me/6281389719748" target="_blank" rel="noopener noreferrer" style={{ display: 'inline-block', overflow: 'hidden' }}>
                      <img
                        src={banner}
                        alt="icon"
                        style={{
                          width: '100%',
                          height: 'auto',
                          transition: 'transform 0.3s ease',
                        }}
                        className="hover-zoom"
                      />
                    </a>
                  </NoBgItem>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {/* Additional Data Rows */}
        <Grid container spacing={2} mt={1}>
          {datas.map((item, index) => (
            <Grid item xs={12} md={item.title === 'Connection' ? 8 : 4} key={index}>
              <Box sx={{
                height: '95%',
                minHeight: "95%",
                maxHeight: "95%",
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                padding: 2,
                boxShadow: 3,
                borderRadius: 2,
                overflow: 'hidden',
                backgroundColor: '#000',
                color: "#fff"
              }}>
                <h1>{item.title}</h1>
                <Box sx={{
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'column',
                }}>
                  <Grid container pl={2} pr={2}>
                    <Grid item xs={12}>
                      {item.details.map((detail, idx) => (
                        <Box key={idx} >
                          {idx !== 0 && <Divider sx={{ backgroundColor: 'rgba(255, 255, 255, 0.2)'}}/>}
                          <Grid container spacing={2} alignItems="flex-start"style={{padding: 15 }} >
                            {/* Kolom 1 */}
                            <Grid item xs={12} sm={4}>
                              <b>{detail.label}</b>
                            </Grid>
                            {/* Kolom 2 */}
                            <Grid item xs={12} sm={8}>
                              <Box sx={{
                                display: detail.isFlag ? 'flex' : 'block',
                                alignItems: detail.isFlag ? 'center' : 'initial',
                                textAlign: 'left',
                                width: '100%'
                              }}>
                                {detail.isFlag ? (
                                  <>
                                    <ReactCountryFlag countryCode={countryCode} svg style={{ marginRight: "10px", width: "2em", height: "2em" }} />
                                    <p style={{ color: '#D8D8D8', textAlign: 'left', margin: 0 }}>{detail.value}</p>
                                  </>
                                ) : (
                                  <p style={{ color: '#D8D8D8', textAlign: 'left', margin: 0 }}>{detail.value}</p>
                                )}
                              </Box>
                            </Grid>
                          {/* Divider di bawah setiap item */}
                          <Divider sx={{ backgroundColor: 'rgba(255, 255, 255, 0.5)' }} />
                          </Grid>
                        </Box>
                      ))}
                    </Grid>
                  </Grid>
                </Box>
                <img src={item.imgSrc} alt="icon" style={{
                  position: 'absolute',
                  top: '20px',
                  right: '20px',
                  width: '50px',
                  height: 'auto'
                }} />
              </Box>
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Divider sx={{ backgroundColor: '#484a4b', marginBottom: 2, marginTop: "20px" }} />
      <Box sx={{ 
        paddingLeft: { xs: 2, sm: 5 }, 
        paddingRight: { xs: 2, sm: 5 },
        display: 'flex', 
        flexDirection: { xs: 'column', sm: 'row' },
        justifyContent: 'space-between', 
        alignItems: 'center', 
        height: "auto",
        color: '#D8D8D8',
        fontSize: '12px',
        textAlign: { xs: 'center', sm: 'left' },
        py: 2
      }}>
        <Typography variant="body2" sx={{ flexGrow: 1 }}>
          Copyright © 2024 BIGNET. All rights reserved.
        </Typography>
        <Box sx={{ display: 'flex', gap: 1, flexDirection: 'row', alignItems: 'center' }}>
          <span>Managed by BIGNET IT Department | </span>
          <span style={{ ...gradientStyle, margin: 0, fontSize: '12px', fontWeight: 'bold' }}>V2.0</span>
        </Box>
      </Box>
      <Grid height={30}></Grid>
    </Box>
  );
}
