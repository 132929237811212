import { useEffect, useState } from 'react';

const useGetIP = () => {
  const [ip, setIp] = useState('');
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchIp = async () => {
      try {
        const response = await fetch('https://ipapi.co/json');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const result = await response.json();
        setIp(result);
      } catch (error) {
        setError('Failed to fetch IP');
        setLoading(false);
      }
    };

    fetchIp();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (ip) {
        try {
          const response = await fetch(`https://lookup-api.bignet.id/api/check_myinfo?ip=${ip.ip}`);
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const result = await response.json();
          setData(result);
        } catch (error) {
          setError('Failed to fetch data');
        } finally {
          setLoading(false);
        }
      }
    };

    fetchData();
  }, [ip]);

  return { ip, data, loading, error };
};

export default useGetIP;
